import React from 'react';

function MonteLema() {
    return (
        <div className="gallery-serator">
        <div className="gallery-section-picture-lema"
          style={{backgroundImage:"url(//live.staticflickr.com/65535/49252267073_685b3a23a5_h.jpg)",backgroundRepeat: 'no-repeat',}}>
            <h1 className="gallery-section-title">Monte Lema</h1>
        </div>
        </div>
    );
}

export default MonteLema;
