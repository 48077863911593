import React, { Component } from "react";
import { connect } from "react-redux";
import {
  removeItem,
  addQuantity,
  subtractQuantity
} from "./cartActions";
import Recipe from "./Recipe";

import {
  Row,
  Col,
} from "reactstrap";

class Cart extends Component {

//loads the products from public/products/products.json
  componentDidMount() {
    fetch('products/products.json')
      .then(response => response.json())
      .then(result => {
        this.setState({items: result.items})
    })
    .then(window.scrollTo(0, 0));
  }

  //to remove the item completely
  handleRemove = id => {
    this.props.removeItem(id);
  };
  //to add the quantity
  handleAddQuantity = id => {
    this.props.addQuantity(id);
    this.forceUpdate();
  };
  //to substruct from the quantity
  handleSubtractQuantity = id => {
    this.props.subtractQuantity(id);
    this.forceUpdate();
  };

  render() {
    let addedItems = this.props.items.length ? (
      this.props.items.map(item => {
        return (
           <div key={item.id}>
            <div className="item-desc">
              <div className="checkout-products">
              <Row>
                <Col className ="card-broad"><b>{item.subtitle}</b></Col>
                <Col className ="card-narrow">
                  <i
                    className="material-icons"
                    onClick={() => {this.handleSubtractQuantity(item.id);}}>
                    remove_circle_outline
                  </i>
                </Col>
                <Col className ="card-narrow">
                  <b>{item.quantity}</b>
                </Col>
                <Col className ="card-narrow">
                  <i
                    className="material-icons"
                    onClick={() => {this.handleAddQuantity(item.id);}}>
                    add_circle_outline
                  </i>
                </Col>
                <Col className ="card-narrow">
                  <button
                    className="checkout-btn delete"
                    onClick={() => {this.handleRemove(item.id);}}>
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </button>
                </Col>
                <Col className ="card-middle"><b>{(Math.round(item.price * item.quantity* 100) / 100).toFixed(2)} CHF </b></Col>
              </Row>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <h2>Kein Honig im Warenkorb!</h2>
    );
    return (
      <div className="container">
        <div className="cart" id="cart-start">
          <h2>Dein Warenkorb</h2>
          <Row>
            <Col className ="card-broad"><b>Sorte</b></Col>
            <Col className ="card-narrow"></Col>
            <Col className ="card-narrow"><b>Gläser</b></Col>
            <Col className ="card-narrow"></Col>
            <Col className ="card-narrow"></Col>
            <Col className ="card-middle"><b>Preis</b></Col>
          </Row>
          <hr></hr>
          <ul className="collection">{addedItems}</ul>
          <hr></hr>
          <Recipe />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    items: state.addedItems
  };
};

const mapDispatchToProps = dispatch => {
  return {
    removeItem: id => {
      dispatch(removeItem(id));
    },
    addQuantity: id => {
      dispatch(addQuantity(id));
    },
    subtractQuantity: id => {
      dispatch(subtractQuantity(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cart);
