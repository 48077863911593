import React from 'react';

function TenutaSanMattia() {
    return (
        <div className="gallery-serator-top">
        <div className="gallery-section-picture-tenuta"
            style={{backgroundImage:"url(//live.staticflickr.com/65535/46917930865_25c5c5d1a2_h.jpg)" ,backgroundRepeat: 'no-repeat'}}>
            <h1 className="gallery-section-title">Malcantone</h1>
        </div>
        </div>
    );
}

export default TenutaSanMattia;
