
import React from "react";
import {
  Button,
  Col,
} from "reactstrap";

class Webcam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageSrc:
        "http://ibmhonig.internet-box.ch/cgi-bin/api.cgi?cmd=Snap&channel=0&rs=" +
        Date.now() +
        "&user=viewer&password=123456",
    };
  }

  componentDidMount() {
    this.updateImage();
  }

  updateImage() {
    this.setState({
      imageSrc:
        "http://ibmhonig.internet-box.ch/cgi-bin/api.cgi?cmd=Snap&channel=0&rs=" +
        Date.now() +
        "&user=admin&password=123456",
    });
  }
  

  render(){

    return(
      <div align="center">
      <Col md="8">
        <h5 className="title-webcam">Live Snapshot Malcantone</h5>
        <div className="date-webcam">{this.timeConverter(Date.now())}</div>
      <Button
        className="btn-round btn btn-outline-primary btn-livecam"
        onClick={(event) => this.updateImage()}
      >
        Update <i className="fas fa-tv"></i>
      </Button>
      </Col>
      <img className="livecam"  src={this.state.imageSrc} alt="Hive Cam" />
      </div>
    )


  }

  timeConverter(UNIX_timestamp){
    var a = new Date(UNIX_timestamp);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes() < 10 ? '0' + a.getMinutes() : a.getMinutes();
    var sec = a.getSeconds() < 10 ? '0' + a.getSeconds() : a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
    return time;
  }

}
export default Webcam;
