import React from "react";
import i18n from 'i18next';

const ChangeLanguage = () => {
  return (
    <div className= "language-flags">
      <img alt="Ch" src={require("../../assets/img/flags/CH.png")} onClick={() => i18n.changeLanguage("de")}></img>
      <img alt="Ch" src={require("../../assets/img/flags/GB.png")} onClick={() => i18n.changeLanguage("en")}></img>
    </div>
  );
};

// extended main view with translate hoc
export default ChangeLanguage;
