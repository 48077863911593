
import Flug from "../../../assets/img/Gallery/mattia/flug.png"
import Artwork from "../../../assets/img/Gallery/mattia/bee-artwork.png"
import Beekeeping from "../../../assets/img/Gallery/mattia/beekeeping.png"
import Honey from "../../../assets/img/Gallery/mattia/honey-nature.png"
import Kasten from "../../../assets/img/Gallery/mattia/kasten.png"
import Lake from "../../../assets/img/Gallery/mattia/lake.png"
import Rose from "../../../assets/img/Gallery/mattia/rose.png"
import Sunflower from "../../../assets/img/Gallery/mattia/sunflower.png"
import Swarm from "../../../assets/img/Gallery/mattia/swarm.png"
import View from "../../../assets/img/Gallery/mattia/tenuta-view.png"
import Tenuta from "../../../assets/img/Gallery/mattia/tenuta.png"
import Hive from "../../../assets/img/Gallery/mattia/yellow-hive-artwork.png"
import Busy from "../../../assets/img/Gallery/mattia/busy.png"
import HoneyComb from "../../../assets/img/Gallery/mattia/golden-honeycomb.png"
import White from "../../../assets/img/Gallery/mattia/white-flower.png"
export const photos = [
  {
    src: Flug,
    width: 8,
    height: 6
  },
  {
    src: Beekeeping,
    width: 6,
    height: 8
  },
  {
    src: Honey,
    width: 6,
    height: 8
  },
  {
    src: Kasten,
    width: 8,
    height: 6
  },
  {
    src: Lake,
    width: 8,
    height: 6
  },
  {
    src: Rose,
    width: 4,
    height: 3
  },
  {
    src: Sunflower,
    width: 8,
    height: 6
  },
  {
    src: Swarm ,
    width: 3,
    height: 4
  },
  {
    src: View,
    width: 4,
    height: 3
  },
  {
    src: Tenuta,
    width: 4,
    height: 3
  },
  {
    src: White,
    width: 4,
    height: 3
  },
  {
    src: HoneyComb,
    width: 4,
    height: 3
  },
  {
    src: Busy,
    width: 3,
    height: 4
  },
  {
    src: Hive,
    width: 4,
    height: 3
  },
  {
    src: Artwork,
    width: 3,
    height: 4
  }
];
