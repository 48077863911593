import React, { useEffect, useState } from "react";

function Scale() {
  const [iframeSize, setIframeSize] = useState({ width: 1000, height: 500 });

  useEffect(() => {
    const resizeIframes = () => {
      const screenWidth = window.innerWidth;
      const parentPadding = 30; // Set the padding of the parent div
      const maxWidth = Math.min(screenWidth - parentPadding, 1000);
      const newHeight = (maxWidth * 500) / 1000;

      setIframeSize({ width: maxWidth, height: newHeight });
    };

    window.addEventListener("resize", resizeIframes);
    resizeIframes();

    return () => {
      window.removeEventListener("resize", resizeIframes);
    };
  }, []);

  return (
    <div>
      <div className="scale-iframe-container">
        <iframe
          className="scale-data-iot"
          title="IOT"
          src={`https://thingspeak.com/apps/matlab_visualizations/338888?width=${iframeSize.width}px&height=${iframeSize.height}px`}
        ></iframe>
      </div>
      <div className="scale-iframe-container">
        <iframe
          className="scale-data-iot"
          title="IOT2"
          src={`https://thingspeak.com/apps/matlab_visualizations/339845?width=${iframeSize.width}px&height=${iframeSize.height}px`}
        ></iframe>
      </div>
    </div>
  );
}

export default Scale;