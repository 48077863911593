import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import HoneyNavbar from "components/Navbars/HoneyNavbar.js";

import Home from "./Shop/Home";
import Cart from "./Shop/Cart";
import ShopPageHeader from "components/Headers/ShopPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

class Shop extends Component {

  render() {
    
    return (
      <BrowserRouter>
        <ShopPageHeader />
            <div className="section section-about-us">
            <HoneyNavbar />
              <div className="shop">
                <Switch>
                  <Route path="/cart" component={Cart} />
                  <Home />
                </Switch>
              </div>
            </div>
          <DefaultFooter/>
      </BrowserRouter>
    );
  }
}

export default Shop;
