import React from 'react';

function Dachlissen() {
    return (
        <div className="gallery-serator">
        <div className="gallery-section-picture-dachlissen"
            style={{backgroundImage:"url(" + require("assets/img/aussicht-affoltern.jpg") + ")" ,backgroundRepeat: 'no-repeat',}}>
            <h1 className="gallery-section-title">Dachlissen</h1>
        </div>
        </div>
    );
}

export default Dachlissen;
