import React, { Component } from "react";
import { connect } from "react-redux";
class Recipe extends Component {

  constructor (props) {
    super(props);
    this.state = { name: '',
                   email: '',
                   adresse: '',
                   ort: '',
                   plz: '',
                   telefon: '',
                   kommentar: '',
                   bezahlung: ''  };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange (event) {
    this.setState({[event.target.name]: event.target.value });
  }

  checkoutClick () {
    if(this.state.name === '' || this.state.email === ''){
      alert("Email und Name fehlt");
    }
    else{
      if(this.props.addedItems.length === 0){
        alert("Du hast keinen Honig im Warenkorb");
      }
      else{
        var text = '';
        // eslint-disable-next-line array-callback-return
        this.props.addedItems.map( item => {
        text = text +'<p>'+item.quantity+ ' x  '+ item.subtitle+' '+ item.price+' CHF</p>'})
        text = text +'<p><b> Preis Total '+ this.props.total+' CHF<b></p>'
        this.handleSubmit(text);
      }
    }
  }

  handleSubmit (text) {
    const templateId = 'template_3ZtHnJx8';
    this.sendFeedback(templateId, {
      order: text,
      kommentar: this.state.kommentar +" Bezahlung: "+ this.state.bezahlung,
      name: this.state.name,
      email: this.state.email,
      adresse: this.state.adresse,
      ort: this.state.ort,
      plz: this.state.plz,
      telefon: this.state.telefon,
      } )
  }

  sendFeedback (templateId, variables) {
    window.emailjs.send(
      'gmail', templateId,
      variables
      ).then(res => {
        console.log('Email successfully sent!')
      })
      //Handle errors here however you like, or use a React error boundary
      .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
      alert("Bestellung abgeschickt, wir haben die eine Bestätigungsemail geschickt. Vielen Dank für deine Bestellung.");
  }

  render() {
    return (
      <div className="container-form">
        <div className="collection">
          <li className="collection-item">
            <h3>Preis Total: {(Math.round(this.props.total * 100) / 100).toFixed(2)} CHF*</h3>
          </li>
          <p>*Preis ohne Lieferungs-/Versandkosten. Falls kostenlose persönliche Lieferung oder Abholung nicht möglich sind, können zusätzliche Versandkosten anfallen.</p>
        </div>
        <form className="test-mailing" >
        <h2>Angaben</h2>
          <div>
            <input
              id="test-mailing"
              name="name"
              onChange={this.handleChange}
              placeholder="Vor- & Nachname *"
              required
              type="name"
              value={this.state.name}
              style={{width: '70%', padding: '3%', height: '30px'}}
            />
          </div>
          <div>
            <input
              id="test-mailing"
              name="email"
              onChange={this.handleChange}
              placeholder="email *"
              required
              type="email"
              value={this.state.email}
              style={{width: '70%', padding: '3%', height: '30px'}}
            />
          </div>
          <div>
            <input
              id="test-mailing"
              name="adresse"
              onChange={this.handleChange}
              placeholder="Adresse"
              required
              type="adresse"
              value={this.state.adresse}
              style={{width: '70%', padding: '3%', height: '30px'}}
            />
          </div>
          <div>
            <input
              id="test-mailing"
              name="plz"
              onChange={this.handleChange}
              placeholder="PLZ"
              required
              type="plz"
              value={this.state.plz}
              style={{width: '70%', padding: '3%', height: '30px'}}
            />
          </div>
          <div>
            <input
              id="test-mailing"
              name="ort"
              onChange={this.handleChange}
              placeholder="Ort"
              required
              type="ort"
              value={this.state.ort}
              style={{width: '70%', padding: '3%', height: '30px'}}
            />
          </div>
          <div>
            <input
              id="test-mailing"
              name="telefon"
              onChange={this.handleChange}
              placeholder="Telefon"
              required
              type="telefon"
              value={this.state.telefon}
              style={{width: '70%', padding: '3%', height: '30px'}}
            />
          </div>
          <div>
            <input
              id="test-mailing"
              name="kommentar"
              onChange={this.handleChange}
              placeholder="Kommentar"
              required
              type="kommentar"
              value={this.state.kommentar}
              style={{width: '70%', padding: '3%', height: '30px'}}
            />
          </div>
          <div>
            <input
              id="test-mailing"
              name="bezahlung"
              onChange={this.handleChange}
              placeholder="Bezahlung: Twint oder Einzahlungsschein"
              required
              type="Bezahlung"
              value={this.state.bezahlung}
              style={{width: '70%', padding: '3%', height: '30px'}}
            />
          </div>
        </form>
        <p>* Zwingende Angaben</p>
        <div className="checkout">
          <button className="checkout-btn" onClick={this.checkoutClick.bind(this)}>Bestellung Absenden</button>
        </div>
        <div className="checkout">
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    addedItems: state.addedItems,
    total: state.total
  };
};

export default connect(
  mapStateToProps
)(Recipe);
