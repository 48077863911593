import BusyHives from "../../../assets/img/Gallery/dachlissen/busy-hives.png"
import Mettmi from "../../../assets/img/Gallery/dachlissen/mettmi.png"
import Buzz from "../../../assets/img/Gallery/dachlissen/hives-buzzing.png"
import HoneyComb from "../../../assets/img/Gallery/dachlissen/honeycomb.png"
import KastenHolz from "../../../assets/img/Gallery/dachlissen/wooden-hives.png"
import Snow from "../../../assets/img/Gallery/dachlissen/snow.png"
import Flow from "../../../assets/img/Gallery/dachlissen/honey-artwork.png"
import Krokus from "../../../assets/img/Gallery/dachlissen/krokus.png"
import Pollen from "../../../assets/img/Gallery/dachlissen/pollen.png"
export const photos2 = [
  {
    src: Mettmi,
    width: 4,
    height: 3
  },
  {
    src: BusyHives,
    width: 5,
    height: 3
  },
  {
    src: Buzz,
    width: 4,
    height: 3
  },
  {
    src: HoneyComb,
    width: 4,
    height: 3
  },
  {
    src: KastenHolz,
    width: 3,
    height: 4
  },
  {
    src: Snow,
    width: 4,
    height: 3
  },

  {
    src: Krokus,
    width: 3,
    height: 4
  },
  {
    src: Pollen,
    width: 4,
    height: 3
  },
  {
    src: Flow,
    width: 3,
    height: 5
  }
];
