import Hills from "../../../assets/img/Gallery/lema/beekeeping-hills.png"
import Views from "../../../assets/img/Gallery/lema/hills.png"
import Inspect from "../../../assets/img/Gallery/lema/tending-hives.png"
import Transport from "../../../assets/img/Gallery/lema/transport.png"
export const photos3 = [
  {
    src: Views,
    width: 4,
    height: 3
  },
  {
    src: Hills,
    width: 1,
    height: 1
  },
  {
    src: Inspect,
    width: 3,
    height: 5
  },
  {
    src: Transport,
    width: 4,
    height: 3
  }
];
