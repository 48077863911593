
import React, { Suspense } from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/nucleo-icons-page-styles.css";
import "assets/css/custom.css";

// general pages
import LandingPage from "views/examples/LandingPage.js";
import BeesPage from "views/examples/BeesPage.js";
import 'views/examples/i18n.js';
// shop imports
import Shop from 'views/examples/Shop';
import cartReducer from 'views/examples/Shop/cartReducer';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import IBMGallery from 'views/examples/IBMGallery';



// loading component for suspense fallback
const Loader = () => (
    <div>loading...</div>
);

const store = createStore(cartReducer)



ReactDOM.render(
<Suspense fallback={<Loader />}>
  <BrowserRouter>
    <Switch>
      <Switch>
        <Route path="/index" render={props => <LandingPage {...props} />} />
        <Route
          path="/shop"
          render={props => <Provider store={store}>
                     <Shop />
                    </Provider>}
         />
        <Route
          path="/home"
          render={props => <LandingPage {...props}/>}
        />
        <Route
          path="/our-bees"
          render={props => <BeesPage {...props} />}
        />
        <Route
          path="/gallery"
          render={props => <IBMGallery {...props} />}
         />
        <Redirect to="/index" />
        <Redirect from="/" to="/index" />
      </Switch>
    </Switch>
  </BrowserRouter>
  </Suspense>,

 document.getElementById('root')
);
