import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import { useTranslation } from 'react-i18next';

// core components

function LandingPageHeader() {
  let pageHeader = React.createRef();
  const { t} = useTranslation();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/zwei3.jpeg") + ")"
          }}
          ref={pageHeader}
        ></div>
        <img
        alt="..."
        className="svglogo"
        src={require("assets/img/Logo-square.png")}
        ></img>

        <div className="content-center">
          <Container>
            <span/>
            <h1 className="title">{t('home.Honey is passion')}</h1>
          </Container>
        </div>
        <div className="page-item">
        <i aria-hidden="true" className="fa fa-angle-double-down">
        </i>
        </div>
      </div>
    </>
  );
}

export default LandingPageHeader;
