import React from "react";
import { Link } from "react-router-dom";
import ChangeLanguage from "./changeLanguage.js";
import { useTranslation } from 'react-i18next';
// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

function HoneyBlankPageNavbar() {
  const [navbarColor] = React.useState("");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const { t} = useTranslation();
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} color="info" expand="lg">
        <Container>
          <UncontrolledDropdown className="button-dropdown">
            <DropdownToggle
              caret
              data-toggle="dropdown"
              href="/landing-page"
              id="navbarDropdown"
              tag="a"
              onClick={e => e.preventDefault()}
            >
              <span className="button-bar"></span>
              <span className="button-bar"></span>
              <span className="button-bar"></span>
            </DropdownToggle>
            <DropdownMenu aria-labelledby="navbarDropdown">
              <DropdownItem href="/landing-page">
                Home
              </DropdownItem>
              <DropdownItem href="/shop" >
                Shop
              </DropdownItem>
              <DropdownItem href="/our-bees">
                {t('navbar.Our bees')}
              </DropdownItem>
              <DropdownItem href="/gallery">
                {t('navbar.Gallery')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <div className="navbar-translate">
            <NavbarBrand
              href="/landing-page"
              id="navbar-brand"
            >
              {t('navbar.name')}
            </NavbarBrand>

            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
            <NavItem>
              <NavLink href="/landing-page#id1" >
                {t('navbar.Our story')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/gallery" >
                {t('navbar.Gallery')}
              </NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="/shop" >
                  {t('navbar.Our products')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/our-bees" tag={Link}>
                  {t('navbar.Our bees')}
                </NavLink>
              </NavItem>
              <NavItem>
                <ChangeLanguage />
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default HoneyBlankPageNavbar;
