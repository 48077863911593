import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Button,
  Input,
  InputGroup,
  Row,
  Col
} from "reactstrap";

class SignUpEmail extends Component {

  constructor (props) {
    super(props);
    this.state = { name: '',
                   email: '',
                   kommentar: '',
                   recaptchaValue: null
                 };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRecaptcha = this.handleRecaptcha.bind(this);
  }

  handleChange (event) {
    this.setState({[event.target.name]: event.target.value });
  }

  checkoutClick () {
    if(this.state.name === '' || this.state.email === ''){
      alert("Bitte email und name angeben!");
    }
    else if (!this.state.recaptchaValue) {
    alert("Bitte reCAPTCHA ausfüllen!");
    }
    else{
      //console.log(this.httpGet("localhost:8000/api/users"));
      //this.httpPost("http://3.11.205.21:8000/api/users/",params);      
        this.handleSubmit();
    }
  }

  handleSubmit () {
    const templateId = 'newsletter_3ZtHnJx9';
    this.sendFeedback(templateId, {
      name: this.state.name,
      email: this.state.email,
      kommentar: this.state.kommentar,
      } )
  }

  sendFeedback (templateId, variables) {
    window.emailjs.send(
      'gmail', templateId,
      variables
      ).then(res => {
        console.log('Erfolgreich Angemeldet!')
      })
      //Handle errors here however you like, or use a React error boundary
      .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err),alert("Erfolgreich Angemeldet! Vielen Dank."))
  }

  handleRecaptcha(value) {
    this.setState({ recaptchaValue: value });
  }



  render() {
    return (
          <Row>
            <Col className="text-center ml-auto mr-auto" lg="6" md="8">
              <InputGroup  className="input-lg">
                <Input
                  placeholder="Name..."
                  type="text"
                  value={this.state.name}
                  onChange={this.handleChange}
                  name="name"
                ></Input>
              </InputGroup>
              <InputGroup  className="input-lg">
                <Input
                  placeholder="Email..."
                  type="text"
                  value={this.state.email}
                  onChange={this.handleChange}
                  name="email"
                ></Input>
              </InputGroup>
              <InputGroup  className="input-lg">
                <Input
                  placeholder="Kommentar..."
                  type="text"
                  value={this.state.kommentar}
                  onChange={this.handleChange}
                  name="kommentar"
                ></Input>
              </InputGroup>
              <div className="recaptcha-wrapper">
                <ReCAPTCHA sitekey="6LfUspAlAAAAAHVwGqdrC_TOpMmvFKUUaZEnSC6A" onChange={this.handleRecaptcha} />
              </div>
              <div className="send-button">
                <Button
                  block
                  className="btn-round"
                  size="lg"
                  onClick={this.checkoutClick.bind(this)}
                >Sign up
                </Button>
              </div>
            </Col>
          </Row>
    );
  }
}

export default (SignUpEmail);
