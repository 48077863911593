import React from "react";
import HoneyBlankPageNavbar from "components/Navbars/HoneyBlankPageNavbar.js";
import DefaultFooter from 'components/Footers/DefaultFooter';
import TenutaSanMattia from 'views/examples/galleryImages/TenutaSanMattia';
import Dachlissen from 'views/examples/galleryImages/Dachlissen';
import MonteLema from 'views/examples/galleryImages/MonteLema';
import Gallery from "react-photo-gallery";
import { photos } from "./galleryImages/TenutaImages";
import { photos2 } from "./galleryImages/DachlissenImages";
import { photos3 } from "./galleryImages/MonteLemaImages";

const TenutaPictures = () => <Gallery photos={photos} />;
const DachlissenPictures = () => <Gallery photos={photos2} />;
const MonteLemaPictures = () => <Gallery photos={photos3} />;




function IBMGallery(){
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return(
<>
    <HoneyBlankPageNavbar />

      <TenutaSanMattia />
      <TenutaPictures />
      <Dachlissen />
      <DachlissenPictures />
      <MonteLema />
      <MonteLemaPictures />

    <DefaultFooter />
</>
  )
}
export default IBMGallery;
