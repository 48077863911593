import React from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import HoneyNavbar from "components/Navbars/HoneyNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import SignUpEmail from "./SignUpEmail.js";
import { useTranslation } from 'react-i18next';

function LandingPage() {
  const { t} = useTranslation();
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
    <>
      <HoneyNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section section-about-us">
          <Container>
            <Row
                    style={{
                    backgroundImage:
                      "url(" + require("assets/img/flow.jpg") + ")" ,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 300,
                      backgroundPosition: 'right'

                    }}>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">{t('home.Title A present by nature')}</h2>
                <h4 className="description">
                {t('home.Text A present by nature')}
                </h4>
                <br></br>
                <Button className="btn-round" color="primary" outline size="lg"
                href="/shop"
                >
                <i className="fa fa-gift"></i>
                <span> </span>
                {t('home.See our Products')}
                </Button>
                <br></br>
              </Col>
            </Row>
            <div className="separator separator-primary"></div>
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/flug.jpg") + ")"
                    }}
                  >
                    <p className="blockquote blockquote-info" id='id1'>
                       {t('home.Quote Our Story')}<br></br>
                      <small>-Victor Hugo</small>
                    </p>
                  </div>
                  <div
                    className="image-container image-bottom"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/kasten.jpg") + ")"
                    }}
                  ></div>
                </Col>
                <Col md="5" >
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/blume.jpg") + ")"
                    }}
                  ></div>
                  <h2 >
                  {t('home.Title Our Story')}
                  </h2>
                  <p>
                  {t('home.Text Our Story P1')}
                  </p>
                  <p>
                  {t('home.Text Our Story P2')}
                  </p>
                  <p>
                  {t('home.Text Our Story P3')}
                  </p>
                </Col>
              </Row>
            </div>
          </Container>

          <Container>
          <Col className="ml-auto mr-auto text-center" md="8">
          <h4 className="title title-youtube">{t('home.Video')}</h4>
          </Col>
          <iframe
          title = "youtube"
          width="1020"
          height="700"
          src="https://www.youtube.com/embed/NMoQxZU4y_o"
          borderframe="0"
          allowFullScreen
          ></iframe>
          <Col className="ml-auto mr-auto text-center" md="8">
          <Button className="btn-round" color="primary" outline size="lg"
          href="/our-bees"
          >
          <i className="fas fa-tv"></i>
          <span> </span>
          {t('home.See our Bees')}
          </Button>
          </Col>
          </Container>
        </div>

        <div className="section section-team text-center">
          <Container>
            <h2 className="title">{t('home.Title Our Locations')}</h2>
            <div className="team">
              <Row>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded img-raised location-pics"
                      src={require("assets/img/tenuta.jpg")}
                    ></img>
                    <h4 className="title">Malcantone (TI)</h4>
                    <p className="category text-info">{t('home.Altitude Tenuta')}</p>
                    <p className="description">
                    {t('home.Text Tenuta')}
                    </p>
                    <Button
                      className="btn-icon btn-round"
                      onClick={()=> window.open("https://www.google.ch/maps/place/Vernate,+Guasti+di+Vernate/@45.9885388,8.8496697,9761m/data=!3m1!1e3!4m13!1m7!3m6!1s0x47842d3284c81db3:0x7337cb3abe59dafa!2sVia+ai+Guasti+12-34,+6991+Neggio!3b1!8m2!3d45.9885251!4d8.8847145!3m4!1s0x47842d32a21d6b41:0x56e7c3febcd64cc7!8m2!3d45.9885192!4d8.8838826", "_blank")}
                    >
                      <i className="fas fa-map-marker-alt"></i>
                    </Button>
                  </div>
                </Col>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded img-raised location-pics"
                      src={require("assets/img/falera.jpeg")}
                    ></img>
                    <h4 className="title">Falera (GR)</h4>
                    <p className="category text-info">{t('home.Altitude Lema')}</p>
                    <p className="description">
                    {t('home.Text Lema')}
                    </p>
                    <Button
                      className="btn-icon btn-round"
                      onClick={()=> window.open("https://www.google.ch/maps/place/Falera/@46.8311181,9.1743771,11126m/data=!3m1!1e3!4m6!3m5!1s0x4784dedb3d1f307d:0x56f8c42774adf53a!8m2!3d46.8012412!4d9.2304912!16zL20vMGQ2M21i", "_blank")}
                    >
                      <i className="fas fa-map-marker-alt"></i>
                    </Button>
                  </div>
                </Col>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded img-raised location-pics"
                      src={require("assets/img/dachlissen.png")}
                    ></img>
                    <h4 className="title">Dachlissen (ZH)</h4>
                    <p className="category text-info">{t('home.Altitude Dachlissen')}</p>
                    <p className="description">
                    {t('home.Text Dachlissen')}
                    </p>
                    <Button
                      className="btn-icon btn-round"
                      onClick={()=> window.open("https://www.google.ch/maps/place/8932+Dachlissen/@47.2592529,8.4296267,2384m/data=!3m1!1e3!4m5!3m4!1s0x4790065ef10683c9:0xade356cb8ae82454!8m2!3d47.259323!4d8.439052", "_blank")}
                    >
                      <i className="fas fa-map-marker-alt"></i>
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
            <Button className="btn-round" color="primary" outline size="lg"
            href="/gallery"
            >
            <i className="fa fa-camera"></i>
            <span> </span>
            {t('home.Pictures')}
            </Button>
          </Container>
        </div>

        <div className="section section-contact-us text-center">
          <Container>
            <h2 className="title">{t('home.We keep you up to date')}</h2>
            <h4 className="description">{t('home.Sign up to our newsletter')}</h4>
            <SignUpEmail></SignUpEmail>
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default LandingPage;
