import React, { Component } from "react";
import { connect } from "react-redux";
import { updateItems } from "./cartActions";
import { addToCart } from "./cartActions";
import Cart from "./Cart";

import {
  Container,
} from "reactstrap";

class Home extends Component {

  componentDidMount() {
    fetch('products/products.json')
      .then(response => response.json())
      .then(data => {
         this.props.updateItems(data.items, data.categories);
       });
  }
  handleClick = (id, availability) => {
    if(availability=== "ausverkauft"){
      alert("Dieser Honig ist leider Ausverkauft!");
    }
    else{
    this.props.addToCart(id);
    alert("Glas im Warenkorb! Die Menge an Gläsern pro Sorte kannst du im Warenkorb weiter unten bestimmen.");
    }
  };

  render() {
    let itemList = this.props.categories.map(categories => {
      return (
        <div className="card" key={categories.id}>
          <div className="card-image">
            <img src={process.env.PUBLIC_URL + '/products/images/'+categories.img} alt={categories.title} />
          </div>
          <div className="card-content">
            <p className="card-title">{categories.title}</p>
            <p className="card-origin"><b>Herkunft:</b><span>&nbsp;&nbsp;</span>{categories.origin}</p>
            <p className="card-story">{categories.desc}</p>
            <Container >
              <table id="t" className="fixed">
                <colgroup>
                  <col className= "narrow"/>
                  <col className= "narrow"/>
                  <col/>
                </colgroup>
                <thead>
                  <tr>
                    <th className= "narrow">Grösse</th>
                    <th className= "narrow">Preis</th>
                    <th>Bestellen</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className= "narrow">{categories.items[0].size}</td>
                    <td className= "narrow">{(Math.round(categories.items[0].price * 100) / 100).toFixed(2)} CHF</td>
                    <td>
                    <span
                      to="/"
                      className={categories.items[0].availability=== "ausverkauft" ? "btn-out" : "shop-btn"}
                      onClick={() => {
                        this.handleClick(categories.items[0].id,categories.items[0].availability);
                      }}
                      >{categories.items[0].availability=== "ausverkauft" ? <em>ausverkauft</em> : <i className="fa fa-cart-arrow-down" aria-hidden="true"></i>}
                    </span>
                    </td>
                  </tr>
                  <tr>
                    <td className= "narrow">{categories.items[1].size}</td>
                    <td className= "narrow">{(Math.round(categories.items[1].price * 100) / 100).toFixed(2)} CHF</td>
                    <td>
                    <span
                      to="/"
                      className={categories.items[1].availability==="ausverkauft" ? "btn-out" : "shop-btn"}
                      onClick={() => {
                        this.handleClick(categories.items[1].id,categories.items[1].availability);
                      }}
                      >{categories.items[1].availability==="ausverkauft"?<em>ausverkauft</em>:<i className="fa fa-cart-arrow-down" aria-hidden="true"></i>}
                    </span>
                    </td>
                </tr>
                </tbody>
              </table>
            </Container>
          </div>
        </div>
      );
    });

    return (
      <div className="container">
        <h2 className="title">Unsere Honige</h2>
        <h4 className="description" text-align="center">In unserem Shop finden Sie eine Auswahl unser exklusivsten Produkte. Unsere Ernte im Mai bis Juli ist natürlich von der Natur abhängig und viele unserer Produkte sind bereits nach wenigen Monaten ausverkauft.</h4>
        <br></br>
        <div className="box">{itemList}</div>
        <div className="button-container">
          <Cart></Cart>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    items: state.items,
    categories: state.categories
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addToCart: id => {
      dispatch(addToCart(id));
    },
    updateItems: (items, categories) => {
      dispatch(updateItems(items, categories));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
