import React from "react";
import Webcam from "./webcam.js";
import Scale from "./scale.js";
import { useTranslation } from 'react-i18next';

// reactstrap components
import {
  Container,
  Button,
} from "reactstrap";

// core components
import HoneyBlankPageNavbar from "components/Navbars/HoneyBlankPageNavbar.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";


function BeesPage() {
  const {t} = useTranslation();
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <HoneyBlankPageNavbar />
      <div className="wrapper">
        <div className="section">
          <Container>
            <h2 className="title">{t('bees.Title intro')}</h2>
            <h5 className="description">
            {t('bees.Text intro')}
            </h5>
            </Container>
            <Container>
              <Webcam id="webcams"></Webcam>
            </Container>
            <Container>
              <Scale></Scale>
              <div align="center">
                <Button
                  className="btn-round btn btn-outline-primary btn-scale"
                  onClick={()=> window.open("https://direct.capaz.de/Webdirekt/PublicViewServlet.html?URL=22bff7d4-bb9f-4680-97d2-ddf2e05dfd69cc843b33-d261-417b-9471-25af18a37693#JuDatenPublicView", "_blank")}>
                  Waage Falera/Malcantone
                  <span> </span>
                  <i className="fas fa-external-link-square-alt"></i>
                </Button>
                <span> &nbsp;</span>
                <Button
                  className="btn-round btn btn-outline-primary btn-scale"
                  onClick={()=> window.open("https://direct.capaz.de/Webdirekt/PublicViewServlet.html?URL=d04f3da3-1b7f-4245-84bd-01ca471bb5fafc02c128-9027-4674-ad61-b155f1adbc68", "_blank")}>
                  Waage Dachlissen
                  <span> </span>
                  <i className="fas fa-external-link-square-alt"></i>
                </Button>
                <span> &nbsp;</span>
                <Button
                  className="btn-round btn btn-outline-primary btn-scale"
                  onClick={()=> window.open("https://thingspeak.com/channels/998803/", "_blank")}>
                  {t('bees.Charts')}
                  <span> </span>
                  <i className="fas fa-external-link-square-alt"></i>
                </Button>
              </div>
            </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default BeesPage;
